import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {
    ROUTE_MAIN,
    ROUTE_PREMIUM_DEEP_LINK,
    ROUTE_STAKING_DEEP_LINK,
    ROUTE_SWAP_DEEP_LINK,
    ROUTE_WALLET_DEEP_LINK
} from 'routes/names';
import {LINK_PREMIUM_DEEPLINK, LINK_STAKING_DEEPLINK, LINK_SWAP_DEEPLINK, LINK_WALLET_DEEPLINK} from 'config';

interface RouteLinks {
    [key: string]: string;
}

const routeLinks: RouteLinks = {
    [ROUTE_PREMIUM_DEEP_LINK]: LINK_PREMIUM_DEEPLINK,
    [ROUTE_WALLET_DEEP_LINK]: LINK_WALLET_DEEPLINK,
    [ROUTE_STAKING_DEEP_LINK]: LINK_STAKING_DEEPLINK,
    [ROUTE_SWAP_DEEP_LINK]: LINK_SWAP_DEEPLINK
};

export default function MobileAppPagesDeepLink() {
    const history = useHistory();
    const location = useLocation();

    React.useEffect(() => {
        const baseDeepLink: string = routeLinks[location.pathname] || '/';

        if (baseDeepLink.startsWith('ime://')) {
            const searchParams = new URLSearchParams(location.search);
            const queryString = searchParams.toString();

            window.location.href = queryString ? `${baseDeepLink}?${queryString}` : baseDeepLink;
            history.push(ROUTE_MAIN);
        } else {
            history.push(ROUTE_MAIN);
        }
    }, [location, history]);

    return null;
}