import MainPage from 'containers/MainPage';
import NotFoundPage from 'containers/NotFoundPage';
import ContactsPage from 'containers/ContactsPage';
import CatalogPage from 'containers/Catalog/CatalogPage';
import ExchangePage from 'containers/ExchangePage';
import CollaborationsPage from 'containers/CollaborationsPage';
import DeepLink from 'components/DeepLink';
import MobileAppPagesDeepLink from 'components/MobileAppPagesDeepLink';
import ChannelPage from 'containers/Catalog/ChannelPage';
import CheckoutPage from 'containers/Catalog/CheckoutPage';
import CreateChannelPage from 'containers/Catalog/CreateChannelPage';
import ClaimPage from 'containers/ClaimPage';
import AirdropPage from 'containers/AirdropPage';
import DownloadDesktopPage from 'containers/DownloadDesktop';
import DownloadAndroidPage from 'containers/DownloadAndroid';
import SwapPage from 'containers/SwapPage';
import DesktopAnalytics from 'containers/DesktopAnalytics';
import DownloadAppsDeepLink from "../components/DownloadAppsDeepLink";

import {Route} from './types';
import {
    ROUTE_AIRDROP,
    ROUTE_BRIDGE,
    ROUTE_CATALOG,
    ROUTE_CHANNEL,
    ROUTE_CHANNEL_CREATE,
    ROUTE_COLLABORATIONS,
    ROUTE_CONTACTS,
    ROUTE_DEEP_LINK,
    ROUTE_DESKTOP_ANALYTICS,
    ROUTE_DOWNLOAD_ANDROID,
    ROUTE_DOWNLOAD_DESKTOP,
    ROUTE_EXCHANGE,
    ROUTE_MAIN,
    ROUTE_PREMIUM_DEEP_LINK,
    ROUTE_VESTING,
    ROUTE_DOWNLOAD_ANDROID_APK,
    ROUTE_DOWNLOAD_DESKTOP_LINUX,
    ROUTE_DOWNLOAD_DESKTOP_MAC,
    ROUTE_DOWNLOAD_DESKTOP_WIN,
    ROUTE_DOWNLOAD_DESKTOP_WIN_64,
    ROUTE_DOWNLOAD_DESKTOP_WIN_PORTABLE,
    ROUTE_DOWNLOAD_DESKTOP_WIN_64_PORTABLE,
    ROUTE_REFERRAL_LINK,
    ROUTE_WALLET_DEEP_LINK,
    ROUTE_SWAP_DEEP_LINK,
    ROUTE_STAKING_DEEP_LINK
} from './names';

export const routesArray: Array<Route> = [
    {
        path: ROUTE_MAIN,
        exact: true,
        component: MainPage,
    },
    // {
    //     path: ROUTE_STAKING,
    //     component: StakingPage,
    // },
    {
        path: ROUTE_DESKTOP_ANALYTICS,
        component: DesktopAnalytics,
    },
    {
        path: ROUTE_BRIDGE,
        component: SwapPage,
    },
    {
        path: ROUTE_AIRDROP,
        component: AirdropPage,
    },
    {
        path: ROUTE_CONTACTS,
        component: ContactsPage,
    },
    // {
    //     path: ROUTE_CATALOG,
    //     component: CatalogPage,
    // },
    // {
    //     path: ROUTE_CATALOG + '/:name',
    //     component: CatalogPage,
    // },
    {
        path: ROUTE_EXCHANGE,
        component: ExchangePage,
    },
    {
        path: ROUTE_COLLABORATIONS,
        component: CollaborationsPage,
    },
    {
        path: ROUTE_DOWNLOAD_ANDROID_APK,
        component: DownloadAppsDeepLink,
    },
    {
        path: ROUTE_DOWNLOAD_DESKTOP_LINUX,
        component: DownloadAppsDeepLink,
    },
    {
        path: ROUTE_DOWNLOAD_DESKTOP_MAC,
        component: DownloadAppsDeepLink,
    },
    {
        path: ROUTE_DOWNLOAD_DESKTOP_WIN,
        component: DownloadAppsDeepLink,
    },
    {
        path: ROUTE_DOWNLOAD_DESKTOP_WIN_64,
        component: DownloadAppsDeepLink,
    },
    {
        path: ROUTE_DOWNLOAD_DESKTOP_WIN_PORTABLE,
        component: DownloadAppsDeepLink,
    },
    {
        path: ROUTE_DOWNLOAD_DESKTOP_WIN_64_PORTABLE,
        component: DownloadAppsDeepLink,
    },
    {
        path: ROUTE_DEEP_LINK,
        component: DeepLink,
    },
    {
        path: ROUTE_REFERRAL_LINK,
        component: DeepLink,
    },
    {
        path: ROUTE_PREMIUM_DEEP_LINK,
        component: MobileAppPagesDeepLink,
    },
    {
        path: ROUTE_WALLET_DEEP_LINK,
        component: MobileAppPagesDeepLink,
    },
    {
        path: ROUTE_SWAP_DEEP_LINK,
        component: MobileAppPagesDeepLink,
    },
    {
        path: ROUTE_STAKING_DEEP_LINK,
        component: MobileAppPagesDeepLink,
    },
    {
        path: ROUTE_CHANNEL + '/:id',
        component: ChannelPage,
        exact: true,
    },
    {
        path: ROUTE_CHANNEL_CREATE,
        component: CreateChannelPage,
        exact: true,
    },
    {
        path: ROUTE_CHANNEL + '/:id/checkout',
        component: CheckoutPage,
    },
    // {
    //     path: ROUTE_VESTING,
    //     component: ClaimPage,
    // },
    {
        path: ROUTE_DOWNLOAD_DESKTOP,
        component: DownloadDesktopPage,
    },
    {
        path: ROUTE_DOWNLOAD_ANDROID,
        component: DownloadAndroidPage,
    },
    {
        path: '',
        component: NotFoundPage,
    }
];
